@font-face {
  font-family: "YesevaOne";
  src: url("/fonts/YesevaOne-Regular.ttf");
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: YesevaOne;
}
